import { StaticImage } from 'gatsby-plugin-image';
import { FC } from 'react';
import tw from 'twin.macro';
import { DesktopOnly, MobileOnly } from '../layout';
import { ContentModal, ContentModalProps } from './content-modal';
import { Footnotes } from './references';
import { Asterisk, H3, LI, NoBreak, UL } from './typography';

type ExtenetModalProps = Omit<ContentModalProps, 'maxHeight'> & {};

/**
 *
 */
export const ExtenetModal: FC<ExtenetModalProps> = (props) => {
  return (
    <ContentModal {...props}>
      <ExtraMobilePadding>
        <H3
          cyan
          tw="font-secondary font-normal normal-case text-2xl md:(font-size[48px] line-height[52px])"
        >
          ExteNET was a phase 3, global, randomized,{' '}
          <NoBreak>placebo-controlled</NoBreak> study
          <sup>1,2</sup>
        </H3>
        <DesktopOnly>
          <StaticImage
            src="../../images/ebc/extenet.png"
            alt="eBC study design schematic"
            aria-label="Diagram of the ExteNET phase 3 study showing study population (n=1420) and placebo-controlled population (n=1420)"
            tw="my-10"
            />
        </DesktopOnly>
        <MobileOnly>
          <StaticImage
            src="../../images/ebc/extenet-mobile.png"
            alt="eBC study design schematic"
            aria-label="Diagram of the ExteNET phase 3 study showing study population (n=1420) and placebo-controlled population (n=1420)"
            tw="my-12 mb-6"
          />
        </MobileOnly>
        <UL yellow>
          <LI>
            <strong tw="text-brand-cyan">Study population:</strong> 2840 women
            with HER2+ eBC (stages I-IIIC) and locally confirmed HR status; all
            patients had prior trastuzumab-based therapy<sup>1,2,§</sup>
          </LI>
          <LI>
            <strong tw="text-brand-cyan">Primary endpoint:</strong> iDFS
            <sup>1,</sup>
            <Asterisk />
          </LI>
          <LI>
            <strong tw="text-brand-cyan">Secondary endpoints:</strong> OS,
            DFS-DCIS, DDFS, time to distant recurrence, CNS metastases, safety
            <sup>1,‖</sup>
          </LI>
          <LI>
            <strong tw="text-brand-cyan">Stratification:</strong> positive nodes
            (0, 1-3, or 4+), HR status, concurrent vs sequential
            trastuzumab-based therapy
            <sup>1</sup>
          </LI>
        </UL>
        <Footnotes
          footnotes={[
            'idfs',
            'results',
            'a-definitive',
            'select-exclusion',
            'antid-prophylaxis',
          ]}
          abreviations={[
            'CNS',
            'DDFS',
            'DFS-DCIS',
            'eBC',
            'GI',
            'HR--hormone',
            'iDFS',
            'ITT',
            'OS',
          ]}
          references={[1, '1,2']}
        />
      </ExtraMobilePadding>
    </ContentModal>
  );
};

const ExtraMobilePadding = tw.div`px-4 pb-12 pt-16 md:(p-0)`;
